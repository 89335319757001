import React, { useEffect, useState } from "react";
import "./App.css";
import "@picocss/pico/css/pico.classless.css";

function App() {
  let [loading, setLoading] = useState(false);
  let [prices, setPrices] = useState<TicketProvider[]>([]);
  let [parkHopper, setParkHopper] = useState(false);
  let [geniePlus, setGeniePlus] = useState(false);
  let [days, setDays] = useState(2);
  let [adults, setAdults] = useState(2);
  let [children, setChildren] = useState(1);
  let [bestTickets, setBestTickets] = useState<TicketAndProvider[]>([]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const prices = await getTicketPrices();
        setPrices(prices);
      } finally {
        setLoading(false);
      }
    })().catch((err) => {
      console.error(err);
    });
  }, []);

  const findBestTicketsWrapper = () => {
    if (prices.length === 0) {
      return;
    }
    const tp = findBestTickets(prices, {
      days,
      parkHopper,
      geniePlus,
      adults,
      children,
    });
    setBestTickets(tp);
  };

  return (
    <div className="container">
      {loading ? <div>Loading...</div> : null}
      <header>
        <h2>Disneyland® Discount Ticket Search</h2>
      </header>
      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            findBestTicketsWrapper();
          }}
        >
          <div>
            <label htmlFor="adults">
              How many adults? (Ages 10+)
              <select
                name="adults"
                id="adults"
                value={adults}
                onChange={(e) => setAdults(parseInt(e.target.value))}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((n) => (
                  <option key={n} value={n}>
                    {n}
                  </option>
                ))}
              </select>
            </label>

            <label>
              How many children? (Ages 3-9)
              <select
                name="children"
                id="children"
                value={children}
                onChange={(e) => setChildren(parseInt(e.target.value))}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((n) => (
                  <option key={n} value={n}>
                    {n}
                  </option>
                ))}
              </select>
            </label>

            <label>
              How Many Days In The Parks?
              <select
                name="days"
                id="days"
                value={days}
                onChange={(e) => setDays(parseInt(e.target.value))}
              >
                {[1, 2, 3, 4, 5].map((n) => (
                  <option key={n} value={n}>
                    {n}
                  </option>
                ))}
              </select>
            </label>

            <fieldset>
              <label htmlFor="parkhopper">
                <input
                  name="parkhopper"
                  id="parkhopper"
                  type="checkbox"
                  role="switch"
                  checked={parkHopper}
                  onChange={(e) => setParkHopper(e.target.checked)}
                />
                Include Park Hopper?
              </label>

              <label htmlFor="genie">
                <input
                  name="genie"
                  id="genie"
                  type="checkbox"
                  role="switch"
                  checked={geniePlus}
                  onChange={(e) => setGeniePlus(e.target.checked)}
                />
                Include Genie+ Service ($25 per ticket per day)?
              </label>
            </fieldset>

            <button type="submit">Calculate</button>
          </div>
        </form>

        {bestTickets.length > 0 ? (
          <TicketSummary tickets={bestTickets} />
        ) : null}
      </div>
    </div>
  );
}

interface TicketProvider {
  name: string;
  url: string;
  tickets: DisneylandTicket[];
}

interface DisneylandTicket {
  days: number;
  parkHopper: boolean;
  geniePlus: boolean;
  adultPrice: number;
  childPrice: number;
}

interface TicketAndProvider {
  ticket: DisneylandTicket;
  provider: TicketProvider;
  totals: Totals;
}

interface Totals {
  adult: number;
  children: number;
  total: number;
}

async function getTicketPrices(): Promise<TicketProvider[]> {
  const response = await fetch("/api/prices");
  return (await response.json()).prices as TicketProvider[];
}

function findBestTickets(
  prices: TicketProvider[],
  options: {
    days: number;
    parkHopper: boolean;
    geniePlus: boolean;
    adults: number;
    children: number;
  }
): TicketAndProvider[] {
  const matched = new Set<string>();
  const keyMaker = (t: TicketAndProvider) =>
    [
      t.provider.name,
      t.ticket.days,
      t.ticket.geniePlus,
      t.ticket.parkHopper,
      t.ticket.adultPrice,
      t.ticket.childPrice,
    ].join(".");
  const elligible: TicketAndProvider[] = [];
  for (const provider of prices) {
    for (const ticket of provider.tickets) {
      if (
        ticket.days === options.days &&
        ticket.parkHopper === options.parkHopper &&
        ticket.geniePlus === options.geniePlus
      ) {
        const entry: TicketAndProvider = {
          ticket,
          provider,
          totals: calcTotals(ticket, options.adults, options.children),
        };
        const entryKey = keyMaker(entry);
        if (!matched.has(entryKey)) {
          matched.add(entryKey);
          elligible.push(entry);
        }
      }
    }
  }
  if (elligible.length === 0) {
    console.error(`no prices match!!!!`);
    return [];
  }
  elligible.sort((a, b) => a.totals.total - b.totals.total);
  return elligible;
}

function calcTotals(
  ticket: DisneylandTicket,
  adults: number,
  children: number
): Totals {
  return {
    adult: adults * ticket.adultPrice,
    children: children * ticket.childPrice,
    total: adults * ticket.adultPrice + children * ticket.childPrice,
  };
}

function TicketSummary(props: { tickets: TicketAndProvider[] }) {
  const best = props.tickets[0];
  const rest = props.tickets.slice(1);
  return (
    <div>
      <h4 className="best">Best Price</h4>
      <div>
        Vendor:{" "}
        <a href={best.provider.url} target="_blank">
          {best.provider.name}
        </a>
      </div>
      <div>
        Ticket: {best.ticket.days} day{" "}
        {best.ticket.parkHopper ? `Park Hopper` : `1 park per day`}{" "}
        {best.ticket.geniePlus ? `with Genie+` : ``}
      </div>
      <div>Cost: ${best.totals.total / 100}</div>
      <h5 className="other">Other Prices</h5>
      {rest.map((other, ix) => (
        <div key={ix} className="other">
          <div>
            Vendor:{" "}
            <a href={other.provider.url} target="_blank">
              {other.provider.name}
            </a>
          </div>
          <div>
            Ticket: {other.ticket.days} day{" "}
            {other.ticket.parkHopper ? `Park Hopper` : `1 park per day`}{" "}
            {other.ticket.geniePlus ? `with Genie+` : ``}
          </div>
          <div>Cost: ${other.totals.total / 100}</div>
          <hr />
        </div>
      ))}
    </div>
  );
}

export default App;
